import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Blagoustroistvo = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO title="Благоустройство" />

    <section id="blagoustroistvo" className="pb80 pt100 bg-dark uslugi blagoustroistvo">

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-title mb25">
              <h1>Благоустройство</h1>
              <p>Территория возле дома или офиса – это место, в котором можно реализовать массу оригинальных идей. Ее можно украсить, поделить на зоны и т.д. Компания «СК МАСТЕР» профессионально занимается благоустройством участков и может преобразить даже самый невзрачный уголок.</p>
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              Что входит
              </h2>
            <p>Для благоустройства территории могут производиться следующие виды работ:</p>
            <ul className="list-group checklist">
              <li>создание розариев, летних/зимних садов;</li>
              <li>укрепление и формирование опорных конструкций;</li>
              <li>укладка брусчатки, плитки или любого другого материала;</li>
              <li>озеленение посредство высадки газонов, цветочных клуб, кустарников и деревьев;</li>
              <li>установка беседок, мостиков и архитектурных конструкций и др.</li>
            </ul>
            <p>
              Благоустройство территории предполагает значительных затрат сил и времени. Чтобы достигнуть желаемого результата, нужно учитывать множество нюансов, таких как климат, уровень освещенности, состав грунты и т.д. Дополнительно создается схема посадки зеленых насаждений, подбираются цвета и т.д.
            </p>
          </div>

        </div>
        <p className='exclamation'>
          Специалисты «СК МАСТЕР» готовы взять на себя решение Ваших задач!
        </p>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h2 className="color-style2">
              Благоустройство от «СК МАСТЕР»
              </h2>
            <p>Обращаясь в нашу компанию за благоустройством участка, Вы получите:</p>
            <ul className="list-group checklist">
              <li>профессиональный подход. Наш штат – квалифицированные мастера с огромным практическим опытом, готовые решать задачи любой сложности;</li>
              <li>качество. Специалисты «СК МАСТЕР» в своей работе использую лучшее оборудование и материалы, закупаемые у проверенных временем поставщиков и производителей;</li>
              <li>экономию средств и времени. Налаженная логистика и внутренние корпоративные процессы, большой опыт и уважение к каждому клиенту – все это привело к тому, что наши цены на благоустройство самые доступные. Кроме того, мы всегда соблюдаем оговоренные сроки, т.к. дорожим своей репутацией;</li>
              <li>надежность. Мы имеем все необходимые допуски и сертификаты. Все наши работы выполняются в полном соответствии с требованиями нормативной документации.</li>
            </ul>
            <p>
              Для уточнения деталей Вы всегда можете связаться с нашими консультантами-менеджерами одним из указанных на сайте способов.
            </p>
          </div>
        </div>
        <p className='exclamation'>
          Благоустройство территории от компании «СК МАСТЕР» - премиум-качество, доступное каждому!
        </p>
      </div>
    </section>

  </Layout >
)

export default Blagoustroistvo
